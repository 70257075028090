<template>

<div class="col-12"> 

    <div class="container mt-5">
        <h2> {{this.$route.params.tag}} のアラート設定  <span v-on:click="close" style="position: absolute;
    right: 5%;
    font-size: 34px;
    cursor: pointer;">X</span></h2>
        
        <ul class="nav nav-tabs" id="alertTab" role="tablist">
            
            <li class="nav-item tab-item" role="presentation">
            
                <button  v-on:click="edit(this.alertTemplate);showNewAlert=true "  class=" text-truncate nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true" >新規</button>
            </li>
        
            <li  v-for="alertSettings in alerts " :key="alertSettings"  class="nav-item" role="presentation">
                
                <button v-on:click="edit(alertSettings);showNewAlert=false" class="text-truncate nav-link tab-item" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false"> {{ alertSettings.title }} </button>
            </li>
        </ul>
        
        <div class="offset-2 col-8"  v-show="editingAlert" >
       
        <br>    
        <div class="mb-3">
            <label for="alertTitle" class="form-label">アラートタイトル</label>
                     <input type="email"  v-model="editingAlert.title" class="form-control" id="email">
  
        </div>

        <div class="mb-3">
            <label for="threshold" class="form-label">閾値 (cm)</label> <br>
            <small>* 条件 A かつ B .. </small>
            
            <div v-for="(threshold, index) in editingAlert.thresholds" :key="threshold" class="offset-2 col-8">
                 <br>
  <div class="row g-3 align-items-center">
    <div class="col-4">
        <input readonly type="text" value="センサデータ" class="form-control">
    </div>
    <div class="col-3 ">
      <select v-model="editingAlert.thresholds[index].operator" class="form-select">
        <option value="lt">&lt;</option>
        <option value="gt">&gt;</option>
      </select>
    </div>
    <div class="col-4">
      <input type="number" v-model="editingAlert.thresholds[index].value" class="form-control">
    </div>
    <div class="col-1">
      <span style="font-weight:bold; cursor: pointer;" v-on:click="cancelData('threshold',index)" >x</span>
    </div> 
  </div>
 
</div>
            <br>
            <b style="cursor: pointer;" v-on:click="addData('threshold')" > + </b>
        </div>

        <div class="mb-3" >
            <label for="email" class="form-label">メールアドレスの登録</label>
             <div v-for=" (email, index) in editingAlert.emails " :key="email" >
              <br>
              <div class="col-11" style="display: inline-block; float:left;">
              <input type="email" class="form-control" v-model.lazy="editingAlert.emails[index]" >
              </div>
                    <div class="col-1" style="display: inline-block; float:left;">
                        <span style="font-weight:bold; cursor: pointer;" v-on:click="cancelData('email',index)" >x</span>
                    </div> <br>
              </div>
             <br>
             <div class="col-12" style="display: inline-block;"> 
            <b style="cursor: pointer;" v-on:click="addData('email')" > + </b>
            </div>
        </div>

         <div class="mb-3" >
            <label for="tel" class="form-label">架電する電話番号</label>
             <div v-for=" (tel, index) in editingAlert.tels " :key="tel" >
              <br>
              <div class="col-11" style="display: inline-block; float:left;">
              <input type="tel" class="form-control" v-model.lazy="editingAlert.tels[index]" >
              </div>
                    <div class="col-1" style="display: inline-block; float:left;">
                        <span style="font-weight:bold; cursor: pointer;" v-on:click="cancelData('tel',index)" >x</span>
                    </div> <br>
              </div>
             <br>
             <div class="col-12" style="display: inline-block;"> 
            <b style="cursor: pointer;" v-on:click="addData('tel')" > + </b>
            </div>
        </div>

        <div class="mb-3">
            
            <label for="phone" class="form-label">再架電の最大回数 0~3 </label> <br>
            <small> *電話に出られなかった場合は 5~10分後に再架電 されます。 </small> <br>
             <small> *端末の留守電設定が有効になっている場合は再架電されません。 </small> <br>
             <div class=" offset-5 col-2">
            <input type="number" min="0" max="3" step="1"  class="form-control" v-model=" editingAlert.recall" >
            </div>
        </div>

      <div class="mb-3">
           
          <label for="duration" class="form-label"> チェック間隔</label><br>
             <div class="offset-5 col-2">
            <select id="duration" class="form-control" v-model="editingAlert.duration">
                <option :value="'5min'" >5分</option>
                <option :value="'10min'" >10分</option>
                <option :value="'1hour'" >1時間</option>

            </select>
            </div>

      </div>

      <div class="mb-3">
           
          <label for="rangeEvaluationMinutes" class="form-label"> 評価データの範囲(10~120分)</label><br>
             <div class="offset-5 col-2">
            
            <small>指定する</small><br>
             <input type="checkbox" id="useRange" v-model="editingAlert.use_range">

            <input v-show="editingAlert.use_range" type="number" min="10" max="120" id="rangeEvaluationMinutes" class="form-control" v-model="editingAlert.range_evaluation_minutes">

            </div>

      </div>


        <div class="mb-3">
           
          <label for="duplicateExclusionMinutes" class="form-label">アラートの重複禁止間隔 </label><br>
             <div class="offset-5 col-2">
            <select id="duplicateExclusionHour" class="form-control" v-model="editingAlert.duplicate_exclusion_hours">
                <option :value="0" >0時間</option>
                <option v-for="hour in 23" :value="hour " v-bind:key="hour">{{ hour }}時間</option>
            </select>
            </div>

          <div class="offset-5 col-2">
            <select id="duplicateExclusionMinutes" class="form-control" v-model="editingAlert.duplicate_exclusion_minutes">
                   <option :value="0" >0分</option>
            
                <option v-for="min in 59" :value="min" v-bind:key="min">{{ min }}分</option>
            </select>
            </div>

        </div>

        
        
        <div class="mb-3">
            <label for="alertMessage" class="form-label">アラートメッセージ</label>
            <textarea class="form-control"  v-model="editingAlert.message" id="alertMessage" rows="3"></textarea>
        </div>
        <br>
        <div class="col-12  ">
         <div class="col-12  ">
        <button v-on:click="save" class="col-6 btn btn-success">この設定で新しく作成する</button>
        </div>
        <br><br><br>
         <div class="col-12  ">
        <button v-on:click="del" v-show="!showNewAlert" class="col-6 btn btn-danger">削除</button>
        </div>
                <br><br><br>
        </div>
    </div>
   </div>
</div>

</template>

<script>
import { defineComponent } from "vue";
import moment from "moment";
import axios from 'axios';


export default defineComponent({
  name: "SnowDeviceAlert",
   data : function() {
      const node_ids = this.$route.params.node_ids;
       return {
        
        durations: ["5min"],
        
        showNewAlert : true,
        alertTemplate : {
               node_ids: node_ids,
               path :"snow/alert/threshold",
               title : "",
               thresholds : [] ,
               emails : [],
               tels :[] , 
               recall :2,
               duration: "5min",
               message : "",
               duplicate_exclusion_minutes :0,
               duplicate_exclusion_hours : 12, 
               range_evaluation_minutes : 10,
               use_range : true
           },
    
        editingAlert : {
               node_ids: node_ids,
               path :"snow/alert/threshold",
               title : "",
               thresholds : [] ,
               emails : [],
               tels :[] , 
               recall : 2, 
               duration: "5min",
               message : "",
               duplicate_exclusion_minutes : 0,
               duplicate_exclusion_hours : 12,
              range_evaluation_minutes : 10,
              use_range : true
           },
        alerts : []
  } } , 
  /*, computed: {
  },*/
  mounted : async function(){
    
  
  if (Object.keys(this.$route.params).length === 0){
   this.$router.push({path: '/snow2023'});
  }

    try 
    {  
        await this.get ();
        this.edit(this.alertTemplate);
    }catch (e){

        console.log(e);
    }
    
   },
  methods: {
      get : async function (){
//duplicate_exclusion_minutes
       const data = await( await fetch ("/api/v4/snow/alert/"+ this.$route.params.tag + "?frequency=every" )).json();
        console.log(data.alerts);
        data.alerts.sort((a, b) => new Date( a.created_at) -new Date( b.created_at));
        
        this.alerts = data.alerts;

       fetch ("/api/v4/snow/alert/"+ this.$route.params.tag + "?frequency=every" ).then((res)=>{console.log(res);});
       
       
      },
      save : async function (){

        if ( ""==this.editingAlert.title ){
          this.$emit( 'show-flash', {"message":"タイトルを設定してください。","type": "warning"});
        return;
        }

       
        if ( 0==this.editingAlert.tels.length && 0==this.editingAlert.emails.length ){
          this.$emit( 'show-flash', {"message":"電話番号またはメールアドレスを設定してください。","type": "warning"});
        return;
        }
      
        if( 
            0 !==this.editingAlert.emails.length &&
            this.editingAlert.emails.every((email)=> !email.match(/.+@.+\..+/) ) ){

            this.$emit( 'show-flash', {"message":"メールアドレスの入力が不正な形式です。","type": "warning"});
            return;
        }

       if( 
         0!==this.editingAlert.tels.length 
       ){
        if(!( this.editingAlert.tels.every((tel)=>   {  return  /^-?\d+(\.\d+)?$/.test( tel ); }))){

            this.$emit( 'show-flash', {"message":"電話番号の不正な形式です。","type": "warning"});
            return;
        }
        if ( !(this.editingAlert.tels.every((tel)=> (tel.length >= 9) ) )){

             this.$emit( 'show-flash', {"message":"電話番号の不正な形式です。","type": "warning"});
          return;
        }
      }

      const res = await fetch ("/api/v4/snow/alert/"+ this.$route.params.tag ,{ method: 'PUT',  headers: { 'Content-Type': 'application/json' },    body: JSON.stringify( this.editingAlert ) });
      if(res.ok){

          this.$emit( 'show-flash', {"message":"アラート設定を保存しました。","type": "success"});
          await this.get ();

      } else {
        
            this.$emit( 'show-flash', {"message":"アラート設定の保存に失敗しました。","type": "warning"});
      }

    } ,
    del : async function (){
        
        if (window.confirm("アラートを削除しますか？")){
            const res = await fetch ("/api/v4/snow/alert/"+ this.$route.params.tag ,{ method: 'DELETE',  headers: { 'Content-Type': 'application/json' },    body: JSON.stringify( this.editingAlert ) });
            if(res.ok){

                this.$emit( 'show-flash', {"message":"アラート設定を削除しました。","type": "success"});
                await this.get ();
        
            }else {
        
                this.$emit( 'show-flash', {"message":"アラート設定の削除に失敗しました。","type": "warning"});
           
            }
        }
      } ,
        edit : function (data){

           const temp = structuredClone (this.alertTemplate);
           const d =structuredClone (data);
           console.log(d);
           d.path = temp.path ;
           d.node_ids = temp.node_ids;
           this.editingAlert = structuredClone (d);


      },
        cancelData: function (type,indexToRemove) {
         
            switch ( type ) {
                case "threshold" :
                
                this.editingAlert.thresholds.splice(indexToRemove, 1);
                break;

                case "email" :
          
                    this.editingAlert.emails.splice(indexToRemove, 1);
                break;
        
                case "tel" :
                    this.editingAlert.tels.splice(indexToRemove, 1);
          break;
          } 
        },
         close(){
        this.$router.push({path: '/snow2023'});
    
      },
        addData: function (type) {
         
         switch ( type ) {
          case "threshold" :
            this.editingAlert.thresholds.push({value:0 , operator:"lt" });
          break;
          case "email" :
            this.editingAlert.emails.push("");
          break;
          case "tel" :
            this.editingAlert.tels.push("");
          break;
          } 
      } 
    }
});
//1810 2679
</script>

<style>
label{

  font-weight: bold;
}
       .tab-item{
            max-width: 150px; 
       }
        .text-truncate {
            width: 150px; /* 文字列を切り取る幅 */
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    </style>